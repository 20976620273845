<template>
  <div>
    <v-toolbar dense>
      <v-icon class="mr-5">mdi-ear-hearing</v-icon>
      <div class="d-flex flex-column justify-center">
        <span class="body"
          >Olá, <span style="color: blue">{{ user.name }}.</span></span
        >
        <span class="caption"
          >Que bom te ver por aqui<v-icon color="blue"
            >mdi-emoticon-cool-outline</v-icon
          ></span
        >
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn icon color="green" @click="cardMeusLeads">
        <v-icon>mdi-cached</v-icon>
      </v-btn>
      <v-menu offset-y transition="slide-x-transition" bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            v-bind="attrs"
            v-on="on"
            class="mr-3"
          ></v-app-bar-nav-icon>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, index) in botoes"
            :key="index"
            @click="setaTitle(item)"
            :disabled="!item.tela"
          >
            <v-list-item-action>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-toolbar>
    <div class="linkContainer ma-4">
      <div
        v-for="item of items"
        class="linkPointer"
        :key="item.id"
        @click="abrePag(item)"
      >
        <v-icon class="ma-3" size="64">
          {{ item.icon }}
        </v-icon>
        <h5 class="ma-5">{{ item.title }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "CentralAuditiva",
  computed: {
    ...mapState(["user"]),
  },
  data: () => ({
    name: "Central auditiva",
    items: [
      {
        id: 1,
        title: "Agenda auditiva",
        name: "Agenda Auditiva",
        icon: "mdi-calendar-month-outline",
        link: "/Agenda",
        content: "Agenda",
      },
      {
        id: 1,
        title: "Disponibilidade de produto",
        name: "Disponibilidade Estoque",
        icon: "mdi-list-box",
        link: "/estoque",
        content: "estoque",
      },
    ],
  }),
  methods: {
    abrePag(item) {
      this.$store.commit("SetTitle", item.title);
      this.$store.commit("SetTabs", {
        name: `${item.title}`,
        content: `${item.content}`,
      });
      // this.$store.commit("SetTabActive", this.tabs.length - 1)
      this.$store.commit("SetTitle", item.title);

      //this.$router.push({ name: `${item.name}` }).catch(() => {});
    },
  },
};
</script>
<style>
.linkContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 5px;
  width: 100%;
}
.linkPointer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 16px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.linkPointer:hover {
  cursor: pointer;
  color: #00009c;
  background-color: rgba(99, 185, 255, 0.3);
}
</style>
